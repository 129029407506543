import React,{useState,useEffect} from 'react'
import Header from '../../components/Layout/Header/Header'
import Newsletter from '../../components/Common/Newsletter'
import Footer from '../../components/Layout/Footer/Footer'
import SearchModal from '../../components/Layout/Header/SearchModal'
import OffWrap from '../../components/Layout/Header/OffWrap'
import Logo from "../../assets/img/logo/finallogo.jpg";

import footerLogo from "../../assets/img/logo/update-logo1.png";
import "./style.css"
import { useDispatch,useSelector } from 'react-redux'
import { addVirtualCard, getVirtualCard } from '../../actions'
import MyVerticallyCenteredModal from '../../components/Modal'
import ThankYou from '../../components/Modal/ThankYou'
import axios from '../../helpers/axios'
import { generatePublicUrl } from '../../urlConfig'

const Card = () => {
	const auth = useSelector(state=>state.auth);
	const user = useSelector(state=>state.user);
	const [userId, setSetUserId] = useState("");
    const [pan, setPan] = useState("");
 	const [aadhaar, setAadhaar] = useState("");
 	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [picture, setPicture] = useState("");
	const [cardShow,setCardShow] = useState(false)

	const [payment, setPayment] = useState(false);
    const [orderId, setOrderId] = useState("");
    const [paymentId, setPaymentId] = useState("");
    const [signature, setSignature] = useState("");
	const [modalShow, setModalShow] = useState(false);
	const [thankModalShow, setThankModalShow] = useState(false);
	const dispatch = useDispatch();

	const buttonText = cardShow ? "Hide Card" : "Show Card";

	console.log("form",auth.user._id,35)
	console.log("form",auth.authenticate,40)
	console.log("form",user.virtualCard,36)
	console.log("form",user.virtualDetails,37)
	
 
	 useEffect(()=>{
		if(!auth.authenticate){
			setCardShow(false)
			setModalShow(false)
		 }
        if(auth.user._id){
			setSetUserId(auth.user._id)
			console.log(auth.user._id,46)
		}else{
			user.virtualDetails=null
		}
	 },[auth.user._id])
	const getCard = async ()=>{
		if(!auth.authenticate){
			setModalShow(true)
			return;
	   } else{
	     setSetUserId(auth.user._id);
	     dispatch(getVirtualCard(userId))
		 setCardShow(!cardShow)
	   }
	}
	const handleCard = async(e)=>{
		
		e.preventDefault();
		// dispatch(getVirtualCard(userId))

		if(!auth.authenticate){
			setModalShow(true)
			return;
	   }
	   
	const form = new FormData();
	form.append("userId",userId)
    form.append("pan", pan);
    form.append("aadhaar", aadhaar);
    form.append("city",city);
    form.append("state", state);
    form.append("picture", picture);
	console.log("form",form)
	console.log("form",userId,pan,aadhaar,city,state,picture)
	// dispatch(addVirtualCard(form))
	const res = await axios.get(`/paymentCard`);
	console.log("Payment Order",res);
	if (res.status !== 200) {
	}
	// alert(productId);
	var options = {
	  key: "rzp_test_fRUmy0uh5zcrHR", // Enter the Key ID generated from the Dashboard
	  amount: res.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
	  currency: res.data.currency,
	  name: "JIITS",
	  description: res.data.notes.desc,
	  image: "https://example.com/your_logo",
	  order_id: res.data.id,

	  handler: async function (response) {
		// alert(response.razorpay_payment_id);
		// alert(response.razorpay_order_id);
		// alert(response.razorpay_signature);
		const {data} = await axios.post(`/verify/razorpay-signature`,response);
		console.log("verify",data)
		setOrderId(response.razorpay_order_id);
		setPaymentId(response.razorpay_payment_id);
		setSignature(response.razorpay_signature);
		setPayment(true);
		if(data.success){
			dispatch(addVirtualCard(form)).then(()=>{
				setThankModalShow(true);
				
			})
		//    dispatch(addOrder(payload)).then(()=>{
		// 		// if(user.orderSuccess){
		// 			
		// 		//   }else{
		// 		//     alert("Sorry!! Your Order Has Been Failed")
		// 		//   }
			  
		//    })
		  
		}else{
			alert(data.message)
		}
	  },
	  prefill: {
		name: "Priyanshuvi",
		email: "priyanshuvi123@example.com",
		contact: "7972757959",
	  },
	  // notes: {
	  //   address: "Razorpay Corporate Office",
	  // },
	  // theme: {
	  //   color: "#3399cc",
	  // },
	};
	var rzp1 = new window.Razorpay(options);
	rzp1.open();
	rzp1.on("payment.failed",function (response){
	  alert(response.error.code);
	  alert(response.error.description);
	  alert(response.error.source);
	  alert(response.error.step);
	  alert(response.error.reason);
	  alert(response.error.metadata.order_id);
	  alert(response.error.metadata.payment_id);
	})


	

	}

  return (
    <>
        <OffWrap />
      <Header
        parentMenu='home'
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass='right_menu_togle hidden-md'
        headerClass='full-width-header header-style1 home8-style4'
        TopBar='enable'
        TopBarClass='topbar-area home8-topbar'
        emailAddress='mylondan186@gmail.com'
        Location='Rohini Nagar Shyam Lawn,Nagpur MH-440013 '
      />
      

    <div className="row">
		
      <div class="col-md-6">
      <form id="form" class="form" onSubmit={handleCard} >
      <div class="form-control">
			<label for="username">PAN</label>
			<input type="number" placeholder="Enter PAN No." id="pan" value={pan} onChange={e=>setPan(e.target.value)} />
			<i class="fas fa-check-circle"></i>
			<i class="fas fa-exclamation-circle"></i>
			<label for="username">Aadhaar</label>
			<input type="number" placeholder="Enter Aadhaar No." id="aadhaar" value={aadhaar} onChange={e=>setAadhaar(e.target.value)} />
			<i class="fas fa-check-circle"></i>
			<i class="fas fa-exclamation-circle"></i>
			<label for="username">State</label>
			<input type="text" placeholder="State" id="state" value={state} onChange={e=>setState(e.target.value)}/>
			<i class="fas fa-check-circle"></i>
			<i class="fas fa-exclamation-circle"></i>
			<label for="username">City</label>
			<input type="text" placeholder="City" id="city" value={city} onChange={e=>setCity(e.target.value)}/>
			<i class="fas fa-check-circle"></i>
			<i class="fas fa-exclamation-circle"></i>
		</div>
    <div class="form-control">
			<label for="username">Photo</label>
			<input type="file" placeholder="Photo" id="photo" name="picture" onChange={e=>setPicture(e.target.files[0])}/>
			<i class="fas fa-check-circle"></i>
			<i class="fas fa-exclamation-circle"></i>
		</div>
		<button type='submit'>Buy Now</button>
	</form>
	{ modalShow && <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />}
				<ThankYou  show={thankModalShow}
                  onHide={() => setThankModalShow(false) } signature={signature} placedOrderId={orderId}/>
      </div>
      <div className='col-md-6'>
	{auth.authenticate && <button  className='hsbtn' onClick={getCard}>{buttonText}</button>}
      {/* <div class="container"> */}
	  {cardShow && <div class="card">
		
		
			<div class="card-inner">
				<div class="front">
					{/* <img src="https://res.cloudinary.com/horlabyc/image/upload/v1629701474/map_yynfqi.png" alt="" class="map-img" />
					<div class="row">
						<img src="https://res.cloudinary.com/horlabyc/image/upload/v1629701473/chip_n5pw0h.png" width="60px" />
						<img src="https://res.cloudinary.com/horlabyc/image/upload/v1629701473/visa_iwqnib.png" alt="" width="80px" />
					</div> */}
					<p>{user.virtualDetails?.aadhaar}</p>
					<div class="row card-number">
						<div className='card-img' style={{position:"absolute",right:"0px",top:"30px",height:"150px",width:"150px",border:"1px solid teal"}}>
						 
						
							<img src={generatePublicUrl(user.virtualDetails?.picture)} alt="" style={{position: "relative",
                                   height: "100%",
                                    width: "100%"}} />   
							
						</div>
						
						{/* <p>1235</p>
						<p>0986</p>
			 			<p>7645</p> */}
					</div>
					<div class="row card-holder">
						<p>CARD HOLDER</p>
						<div class="row name">
						    <p>{auth.user.firstName} {auth.user.lastName}</p>
						</div> 
						
						<p>VALID TILL</p>
						<div class="name" style={{display:"flex",justifyContent:"space-between"}}>
						   <p>10 / 25</p>
						{/* </div> */}
						{/* <div class="row name"> */}
						 <p>Two Thousand Only</p>
						 </div>
						
					</div>
					
				</div>
				<div class="back">
					<img src="https://res.cloudinary.com/horlabyc/image/upload/v1629701474/map_yynfqi.png" alt="map" class="map-img" />
					<div class="bar"></div>
					<div class="row card-cvv">
						<div>
							<img src="https://res.cloudinary.com/horlabyc/image/upload/v1629701473/pattern_kvky32.png" alt="pattern" />
						</div>
						<p>824</p>
					</div>
					<div class="row card-text">
						<p>This is a virtual card design built with HTMl and CSS. You fit copy and use the design too </p>
					</div>
					<div class="row signature">
						<p>CUSTOMER SIGNATURE</p>
						<img src="https://res.cloudinary.com/horlabyc/image/upload/v1629701473/visa_iwqnib.png" alt="visa logo" width="80px" />
					</div>
				</div>
			</div>
		</div>}
	</div> 
     
      </div>
	  


       {/* <Newsletter
        sectionClass='rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70'
        titleClass='title mb-0 white-color'
      /> */}
      <Footer
        footerClass='rs-footer home9-style main-home'
        footerLogo={footerLogo}
      />
      <SearchModal />
    </>
   
  )
}

export default Card