import React from "react";

const Jiits = () => {
  return (
    <>
      <h1>Welcome to My JIITS School</h1>
    </>
  );
};

export default Jiits;
