import React, { useState,useEffect } from 'react';
import ModalVideo from 'react-modal-video';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useParams } from 'react-router-dom';
import {getProductDetailsById} from '../../../actions/product.action';

// Image
import videoImg from '../../../assets/img/about/about-video-bg2.png';

const CourseSidebar = () => {
    const product = useSelector(state=>state.product);
    const dispatch = useDispatch();
    const {productId} = useParams();
    console.log("SingleCourse",productId);

    useEffect(() => {
        const payload = {
          params: {
            productId,
          },
        };
        dispatch(getProductDetailsById(payload));
      }, []);

    console.log("Product Details",product.productDetails._id);

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="inner-column">
            <ModalVideo channel='youtube' isOpen={isOpen} videoId='YLN1Argi7ik' onClose={() => { openModal(); }} />
            <div className="intro-video media-icon orange-color2">
                <img className="video-img" src={videoImg} alt="Video Image" />
                <Link className="popup-videos" onClick={() => { openModal(); }} >
                    <i className="fa fa-play"></i>
                </Link>
                <h4>Preview this course</h4>
            </div>
            <div className="course-features-info">
                <ul>
                    <li className="lectures-feature">
                        <i className="fa fa-files-o"></i>
                        <span className="label">Lectures</span>
                        <span className="value">{product.productDetails?.courseVideos?.length}</span>
                    </li>
                    
                    <li className="quizzes-feature">
                        <i className="fa fa-puzzle-piece"></i>
                        <span className="label">Quizzes</span>
                        <span className="value">0</span>
                    </li>
                    
                    <li className="duration-feature">
                        <i className="fa fa-clock-o"></i>
                        <span className="label">Duration</span>
                        <span className="value">{product.productDetails?.duration}</span>
                    </li>
                    
                    <li className="students-feature">
                        <i className="fa fa-users"></i>
                        <span className="label">Students</span>
                        <span className="value">21</span>
                    </li>
                    
                    <li className="assessments-feature">
                        <i className="fa fa-check-square-o"></i>
                        <span className="label">Assessments</span>
                        <span className="value">Yes</span>
                    </li>
                </ul>
            </div>                
            <div className="btn-part">
                <div className="btn readon2 orange" style={{fontSize:"20px",fontWeight:"900px"}}>&#8377; {product.productDetails?.price}</div>
                <NavLink to={`/shop/checkOut/${product.productDetails?._id}`} className="btn readon2 orange-transparent">Buy Now</NavLink>
            </div>
        </div>
    );
}

export default CourseSidebar;