import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import Footer from '../../components/Layout/Footer/Footer'
import Header from '../../components/Layout/Header/Header'
import OffWrap from '../../components/Layout/Header/OffWrap'
import SearchModal from '../../components/Layout/Header/SearchModal'
import Logo from "../../assets/img/logo/finallogo.jpg";
import "./style.css"

import footerLogo from "../../assets/img/logo/update-logo1.png";

const Coin = () => {


    
    function Example() {
      const [show, setShow] = useState(false);
    
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
    
      return (
        <>
          <Button variant="primary" onClick={handleShow}>
            Launch demo modal
          </Button>
    
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>My coin Rs-200</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                  {/* coin */}
                <div class="coin copper"></div>
                    {/* <div class="coin flip silver"></div>
                    <div class="coin"></div> */}
              <Form className="form">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Enter Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Amount"
                    autoFocus
                  /> 
                  <div className='d-flex'>
                     <div className="small-card"><p>Less Than 1000 For Silver Coin</p></div>
                     <div className="small-card"><p>More Than 2000 For Copper Coin</p></div>
                     <div className="small-card"><p>More Than 3000 For Gold Coin</p></div>
                  </div>
                
                  {/* <p>More Than 2000 For Copper Coin</p>
                  <p>More Than 3000 For Gold Coin</p> */}
                </Form.Group>
                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Example textarea</Form.Label>
                  <Form.Control as="textarea" rows={3} />
                </Form.Group> */}
                <Button variant="primary" onClick={handleClose}>
                  Buy Now
              </Button>
              </Form>
               
            </Modal.Body>
            {/* <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Save Changes
              </Button>
            </Modal.Footer> */}
          </Modal>
        </>
      );
    }
    

  return (
    <>
    <OffWrap />
    <Header
        parentMenu='home'
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass='right_menu_togle hidden-md'
        headerClass='full-width-header header-style1 home8-style4'
        TopBar='enable'
        TopBarClass='topbar-area home8-topbar'
        emailAddress='mylondan186@gmail.com'
        Location='Rohini Nagar Shyam Lawn,Nagpur MH-440013 '
      />
      <Example />
    

    <Footer  
    footerClass='rs-footer home9-style main-home'
    footerLogo={footerLogo} />
    <SearchModal />
    
    </>
  )
}

export default Coin