import React from "react";
import { Link } from "react-router-dom";
import { generatePublicUrl } from "../../urlConfig";

const CourseSingleTwo = (props) => {
  const {
    courseClass,
    courseImg,
    courseTitle,
    catLink,
    coursePrice,
    courseCategory,
    userCount,
    userRating,
    courseId,
  } = props;
  return (
    <div className={courseClass ? courseClass : "courses-item"}>
      <div className='img-part'>
        <img src={generatePublicUrl(courseImg)} alt={courseTitle} />
      </div>
      <div className='content-part'>
        <ul className='meta-part'>
          <li>
            <span className='price'>{coursePrice ? coursePrice : "00.00"}</span>
          </li>
          <li>
            <Link
              className='categorie'
              to={`/course/course-single/${courseId}`}
            >
              {courseCategory ? courseCategory : "Web Development"}
            </Link>
          </li>
        </ul>
        <h3 className='title'>
          <Link to={`/course/course-single/${courseId}`}>
            {courseTitle
              ? courseTitle
              : "Become a PHP Master and Make Money Fast"}
          </Link>
        </h3>
        <div className='bottom-part'>
          <div className='info-meta'>
            <ul>
              <li className='user'>
                <i className='fa fa-user'></i> {userCount ? userCount : "245"}
              </li>
              <li className='ratings'>
                <i className='fa fa-star'></i>
                <i className='fa fa-star'></i>
                <i className='fa fa-star'></i>
                <span>({userRating ? userRating : "05"})</span>
              </li>
            </ul>
          </div>
          <div className='btn-part'>
            <Link to={`/course/course-single/${courseId}`}>
              {props.btnText}
              <i className='flaticon-right-arrow'></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSingleTwo;
