import React from "react";

function Francise() {
  return (
    <>
      <h1> Welcome to Francise</h1>
      <h2>
        Partner With JIITS, A Leading Global Digitally Enabled Career Guidance
        Platform
      </h2>
      <div>
        <p>
          ProTeen is an integrated digital career guidance platform that helps
          students through the process of selecting an academic stream,
          identifying their interests and aptitude, and enabling skill
          development required for 21st century employment. Designed for both
          high school and college students, ProTeen is the essential first step
          towards becoming career-ready. In our quest to spread career awareness
          and guidance globally, ProTeen is looking to forge alliances with
          like-minded franchise partners who are willing to invest the time,
          have the commitment and the passion required to be successful in
          achieving the long-term vision of making career guidance a reality for
          every student. ProTeen understands the critical nature and impact of
          career guidance in the life of a young and confused student. Our
          promise is to deliver the highest quality of career guidance and
          counseling to effectively shape and mold the future of our country’s
          youth.
        </p>
        <form>
          <div className="mb-3">
            <h3>Partner With JIITS</h3>
            <label for="exampleInputEmail1" className="form-label">
              Name
            </label>
            <input type="text" className="form-control" />
            <label for="exampleInputEmail1" className="form-label">
              Phone
            </label>
            <input type="number" className="form-control" />
            <label for="exampleInputEmail1" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
            <div id="emailHelp" className="form-text">
              We'll never share your email with anyone else.
            </div>
          </div>

          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
            />

            <label className="form-check-label" for="exampleCheck1">
              Check me out
            </label>
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
      <div>
        <div>
          <span>Backed by Science and Research</span>
          <span>Certified Counsellor Program </span>
          <span> Part of NEAT, By MoE & AICTE</span>
        </div>
        <h3>JIITS Counselling Partner Program</h3>JIITS implements a unique
        high-tech, high-touch approach providing the optimal combination of
        integrated academic and career guidance platform paired with trained
        career experts.
      </div>
      <div>
        <h1>Get Started</h1>
        <div>
          <div>
            <span>Minimum 600 sq. ft Area</span> You must have a minimum space
            of 600 sq. feet to house a counselling room, a lab, and a meeting
            hall in your career centre.
          </div>
          <div>
            <span>Financial Investment</span> The initial investment depends on
            your location and the city that you are based in. We estimate that
            this will be in the range of Rs. 5-10 lacs.
          </div>
          <div>
            <span>Long-term Vision</span> Partners must be willing to invest the
            time, have the commitment and the passion required to be successful
            in achieving the long-term vision of making career guidance a
            reality for every student.
          </div>
        </div>
      </div>
    </>
  );
}

export default Francise;
