import { Modal } from "react-bootstrap"
import {NavLink} from "react-router-dom"
const ThankYou=(props)=>{
    return(
        <>
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        {/* <Modal.Title id="contained-modal-title-vcenter">
        Login / Register
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
             {/* <!-- thank-you-wrapper --> */}
    <section class="thank-you-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="thank-you-page-logo">
              {/* <a href="#">
                          Your company logo
                      </a> */}
            </div>
  
            <div class="thank-you-page-content">
              <h1 className="text-success">Transaction Successfully</h1>
              <p>Welcome In JIITS,Thank You For Purchasing Our Awesome Products </p>
              <p>Your OrderId: <span><strong>{props.placedOrderId}</strong></span></p>
              <p>Your Signature </p><p><span><strong>{props.signature}</strong></span></p>
              <NavLink to={`/`} class="btn btn-primary arrow-icon"> Go back to Homepage </NavLink>
            </div>
            {/* <ul class="footer-nav">
              <li> <a href="#">Terms and Conditions </a> </li>
              <li> <a href="#"> Privacy Policy </a> </li>
              <li> <a href="#"> FAQ </a> </li>
              <li> <a href="#"> Contact Us </a> </li>
            </ul>
            <div class="thank-you-copy">
              <p> Your company &copy; 2020 All Rights Reserved </p>
            </div> */}
          </div>
  
        </div>
  
      </div>
    </section>
    {/* <!-- thank-you-wrapper --> */}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide} style={{height: "31px",
         padding: "0 0 0",
          width: "71px"}}>Close</Button>
      </Modal.Footer> */}
    </Modal>
        </>
    )
  
}
export default ThankYou