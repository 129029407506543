import React from "react";
import { Link } from "react-router-dom";

import SectionTitle from "../../components/Common/SectionTitle";

import { useSelect } from "@mui/base";
import { useSelector } from "react-redux";

const Courses = () => {
  const category = useSelector((state) => state.category);

  return (
    <div></div>
    // <div className='rs-popular-courses main-home event-bg pt-100 pb-100 md-pt-70 md-pb-70'>
    //   <div className='container'>
    //     <div className='links d-flex g-10'>
    //       <div className='course_links'>
    //         {category.categories[0]?.children.map((curElem, index) =>
    //           index < 4 ? (
    //             <Link to={`course/${curElem.slug}?cid=${curElem._id}`}>
    //               <p>{curElem.name}</p>
    //             </Link>
    //           ) : (
    //             ""
    //           )
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Courses;
