import React from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";

import CareerSection from "../../components/Career/CareerSection";
// Image
import Logo from "../../assets/img/logo/update-logo1.png";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";

const CourseTwo = () => {
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu='course'
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass='right_menu_togle hidden-md'
        headerClass='full-width-header header-style1 home8-style4'
        TopBar='enable'
        TopBarClass='topbar-area home8-topbar'
        emailAddress='mylondan186@gmail.com'
        Location='Rohini Nagar Shyam Lawn,Nagpur MH-440013 '
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle='CAREER @ JOB IN IT TRAINING AND SOLUTIONS'
        pageName='career'
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-start */}

      {/* Course Main */}
      {/* <CareerMain /> */}
      {/* Course Main */}
      <CareerSection />
      <Newsletter
        sectionClass='rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70'
        titleClass='title mb-0 white-color'
      />

      <Footer footerClass='rs-footer home9-style main-home' />
      <SearchModal />
    </React.Fragment>
  );
};

export default CourseTwo;
