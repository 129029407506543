import React from "react";
const Jobs = () => {
  return (
    <>
      <h1>Marketing Solutions in Jiits</h1>
    </>
  );
};

export default Jobs;
