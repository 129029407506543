import React from "react";
import OffWrap from "../../components/Layout/Header/OffWrap";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import HomeMain from "./HomeMain";
import Logo from "../../assets/img/logo/finallogo.jpg";

import footerLogo from "../../assets/img/logo/update-logo1.png";

const HomePage = () => {
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu='home'
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass='right_menu_togle hidden-md'
        headerClass='full-width-header header-style1 home8-style4'
        TopBar='enable'
        TopBarClass='topbar-area home8-topbar'
        emailAddress='mylondan186@gmail.com'
        Location='Rohini Nagar Shyam Lawn,Nagpur MH-440013 '
      />

      <HomeMain />
      <Newsletter
        sectionClass='rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70'
        titleClass='title mb-0 white-color'
      />
      <Footer
        footerClass='rs-footer home9-style main-home'
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default HomePage;
