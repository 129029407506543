import React from "react";
import {Routes, BrowserRouter,Route} from "react-router-dom";

//Custom Components

import Home from "../pages/home";

import About from "../pages/about";
import CourseOne from "../pages/course";



import CourseSingle from "../pages/course/course-single";
import CourseCategoryPage from "../pages/course-categories";
import Team from "../pages/team";

import TeamSingle from "../pages/team/team-single";
import Event from "../pages/event";

import Gallery from "../pages/gallery";

import Faq from "../pages/faq";
import Login from "../pages/login";
import Register from "../pages/register";

import CareerMain from "../pages/career";
import MyAccount from '../pages/shop/my-account';
import Cart from '../pages/shop/cart';
import CheckOut from '../pages/shop/checkout';







import Contact from "../pages/contact";
import Service from "../pages/service/servicemain/index";


import Error from "../pages/404";
import LoadTop from "../components/Common/ScrollTop/LoadTop";
import DegreeSec from "../pages/degree/DegreeSec";
import JobDetail from "../pages/job-opportunity/JobDetail";
import FranciseMain from "../pages/franchise/FranciseMain";
import JiitsMain from "../pages/jiitsSchool/JiitsMain";
import Blog from "../pages/blog";
import Card from "../pages/Card";
import Coin from "../pages/Card/Coin"


const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <LoadTop />
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/home" element={<Home/>} />

          <Route path="/about" element={<About/>} />

          <Route path="/card" element={<Card/>} />
          <Route path="/coin" element={<Coin/>} />

          <Route path="/course/course-single/:productId" element={<CourseSingle/>} />
          <Route path="/course-categories" element={<CourseCategoryPage/>} />
          <Route path="/team" exact element={<Team/>} />
          
          <Route path="/team/team-single" element={<TeamSingle/>} />
          <Route path="/event" element={<Event/>} />
          <Route path="/blog" element = {<Blog/>} />
          
          <Route path="/gallery" element={<Gallery/>} />

          
          <Route path="/faq" element={<Faq/>}/>
          <Route path="/login" element={<Login/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/shop/my-account" element={<MyAccount/>} />
          <Route path="/job-opportunity"  element={<JobDetail/>} />
          <Route path="/jiitsSchool"  element={<JiitsMain/>} />

          <Route path="/shop/cart/:productId" element={<Cart/>} />
          <Route path="/shop/checkout/:productId" element={<CheckOut/>} />

          <Route path="/:cid" element={<CareerMain/>} />
          <Route path="/:courseSlug/:cid" element={<CourseOne/>} />



          {/* </Route> */}

         

          
         
          <Route path="/contact" element={<Contact/>} />
          <Route path="/service" element={<Service/>} />
          <Route path="/degree" element={<DegreeSec/>} />
          <Route path="/francise" element={<FranciseMain/>}/>


    
    
          <Route path="*" element={<Error/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
