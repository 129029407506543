import React from "react";
import Team from "./TeamSection";
import Blog from "./BlogSection";
import VideoSection from "./VideoSection1";
import AboutTabSection from "./AboutTabSection";
import AboutTextSection from ".//AboutTextSection";
import BrandThree from "../../components/Common/Brand/BrandThree";
import AboutCounter from "./CounterSection";
import Testimonial from "./TestimonialSection";
import ScrollToTop from "../../components/Common/ScrollTop";

const AboutMain = () => {
  return (
    <React.Fragment>
      {/* AboutCounter Start */}
      <AboutTextSection />
      <AboutCounter />

      {/* AboutCounter End */}

      {/* AboutVideo Start */}

      <VideoSection />
      {/* AboutVideo End */}
      <AboutTabSection />

      {/* Team Section Start */}
      <Team />
      {/* Team Section End */}

      <Testimonial />
      {/* Testimonial Section End */}

      {/* Blog Section Start */}
      <Blog />
      <BrandThree brandClass='rs-partner pb-92 md-pb-70' />

      {/* Blog Section End */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName='scrollup orange-color' />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default AboutMain;
