import React from "react";
import { Helmet } from "react-helmet";
import OffWrap from "../../components/Layout/Header/OffWrap";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ScrollToTop from "../../components/Common/ScrollTop";
import Newsletter from "../../components/Common/Newsletter";
import Logo from "../../assets/img/logo/update-logo1.png";
import favIcon from "../../assets/img/fav-orange.png";

import Jiits from "../../components/JiitsSchool/jiits";
function JiitsMain() {
  return (
    <React.Fragment>
      <OffWrap />
      {/* Header */}
      <Helmet>
        <link rel='icon' href={favIcon} />
      </Helmet>
      <Header
        parentMenu='service'
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass='right_menu_togle hidden-md'
        headerClass='full-width-header header-style1 home8-style4'
        TopBarClass='topbar-area home8-topbar'
        TopBar='enable'
        emailAddress='mylondan186@gmail.com'
        Location='Rohini Nagar Shyam Lawn,Nagpur MH-440013 '
      />
      <Jiits />{" "}
      <Newsletter
        sectionClass='rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80'
        titleClass='title mb-0 white-color'
      />
      {/* Newsletter Section End */}
      {/* Footer */}
      <Footer footerClass='rs-footer home9-style main-home' />
      {/* Footer */}
      {/* SearchModal */}
      <SearchModal />
      {/* SearchModal */}
      {/* scrolltop-start */}
      <ScrollToTop scrollClassName='scrollup orange-color' />
    </React.Fragment>
  );
}

export default JiitsMain;
