import React,{useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import CountryList from './CountryList';
// import DistrictList from './DistrictList';
import "./CheckoutMain.css"
import paypalLogo from '../../assets/img/paypal.png';
import axios from '../../helpers/axios'
import {addOrder} from '../../actions/user.action'
import MyVerticallyCenteredModal from "../Modal"
import ThankYou from '../Modal/ThankYou';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import { NavLink, useParams } from 'react-router-dom';
import {useDispatch, useSelector } from 'react-redux';
const CheckoutMain = () => {
    const {productId} = useParams();
    const product = useSelector(state=>state.product);
    const auth = useSelector((state) => state.auth);
    const user = useSelector(state=>state.user);
    const [payment, setPayment] = useState(false);
    const [orderId, setOrderId] = useState("");
    const [paymentId, setPaymentId] = useState("");
    const [signature, setSignature] = useState("");
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const [thankModalShow, setThankModalShow] = useState(false);
      const payload = {
        productId:productId,
        totalAmount:product.productDetails.price,
        items:1,
        paymentStatus: "success",
        paymentType: "card",
      };
      console.log(user.placedOrderId,38)
   
    
 
    const buyNow = async (productId) => {
        if(!auth.authenticate){
             setModalShow(true)
             return;
        }
        const res = await axios.get(`/payment/${productId}`);
        console.log("Payment Order",res);
        if (res.status !== 200) {
        }
        // alert(productId);
        var options = {
          key: "rzp_test_fRUmy0uh5zcrHR", // Enter the Key ID generated from the Dashboard
          amount: res.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: res.data.currency,
          name: "JIITS",
          description: res.data.notes.desc,
          image: "https://example.com/your_logo",
          order_id: res.data.id,
    
          handler: async function (response) {
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);
            const {data} = await axios.post(`/verify/razorpay-signature`,response);
            console.log("verify",data)
            setOrderId(response.razorpay_order_id);
            setPaymentId(response.razorpay_payment_id);
            setSignature(response.razorpay_signature);
            setPayment(true);
            if(data.success){
               dispatch(addOrder(payload)).then(()=>{
                    // if(user.orderSuccess){
                        setThankModalShow(true);
                    //   }else{
                    //     alert("Sorry!! Your Order Has Been Failed")
                    //   }
                  
               })
              
            }else{
                alert(data.message)
            }
          },
          prefill: {
            name: "Priyanshuvi",
            email: "priyanshuvi123@example.com",
            contact: "7972757959",
          },
          // notes: {
          //   address: "Razorpay Corporate Office",
          // },
          // theme: {
          //   color: "#3399cc",
          // },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed",function (response){
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        })
      };
    //   console.log(orderId,paymentId,signature,92)
    return (
        <div className="rs-checkout orange-style pt-100 pb-100 md-pt-70 md-pb-70">
            <div className="container">
                {/* <form id="coupon-form" action="#"> */}
                    <Accordion className="coupon-toggle" allowZeroExpanded="true">
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="card-title">
                                        <span><i className="fa fa-window-maximize"></i> Have a coupon?</span>
                                        <div className="accordion-toggle">Click here to enter your code</div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="card-body">
                                    <p>If you have a coupon code, please apply it below.</p>
                                    <div className="coupon-code-input">
                                        <input type="text" name="coupon_code" placeholder="Coupon code" required />
                                    </div>
                                    <button className="btn-shop orange-color" type="submit">Apply Coupon</button>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                {/* </form> */}
                <ThankYou  show={thankModalShow}
                  onHide={() => setThankModalShow(false) }/>
                <div className="full-grid">
                    {/* <form id="checkout-form" action="#">
                        <div className="billing-fields">
                            <div className="checkout-title">
                                <h3>Billing details</h3>
                            </div>
                        </div>
                        <div className="form-content-box">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>First Name *</label>
                                        <input id="fname" name="fname" className="form-control-mod" type="text" required />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Last Name *</label>
                                        <input id="lname" name="lname" className="form-control-mod" type="text" required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Company name (optional)</label>
                                        <input id="cname" name="cname" className="form-control-mod" type="text" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Country*</label>
                                        <CountryList />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Street address *</label>
                                        <input id="hnumber" name="hnumber" className="form-control-mod margin-bottom" type="text" placeholder="House number and street name" required />
                                        <input id="hnumber-2" name="hnumber" className="form-control-mod" type="text" placeholder="Apartment, suite, unit etc. (optional)" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Town / City *</label>
                                        <input id="city" name="city" className="form-control-mod" type="text" required />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>District*</label>
                                        <DistrictList />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Postcode / ZIP (optional)</label>
                                        <input id="pcode" name="pcode" className="form-control-mod" type="text" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Phone *</label>
                                        <input id="number" name="number" className="form-control-mod" type="text" required />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Email address *</label>
                                        <input id="email" name="email" className="form-control-mod" type="email" required />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="additional-fields">
                            <div className="form-content-box">
                                <div className="checkout-title">
                                    <h3>Additional information</h3>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>Order notes (optional)</label>
                                            <textarea placeholder="Notes about your order, e.g. special notes for delivery."></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    {/* </form> */}
                        <div className="ordered-product">
                            <div className="checkout-title">
                                <h3>Your order</h3>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Course</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{product.productDetails.name} <strong><i className="fa fa-close"></i>1</strong></td>
                                        <td>{product.productDetails.price}</td>
                                    </tr>
                                    <tr>
                                        <td>Discount</td>
                                        <td>{0}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    {/* <tr>
                                        <th>Subtotal</th>
                                        <th>$60.00</th>
                                    </tr> */}
                                    <tr>
                                        <th>Total</th>
                                        <th>{product.productDetails.price}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="payment-method mt-40 md-mt-20">
                            <div className="top-area">
                                <div className="payment-co">
                                    <span>PayPal</span>
                                    <img src={paypalLogo} alt="PayPal Logo" />
                                </div>
                                <div className="p-msg">Pay via PayPal you can pay with your credit card if you dont have a PayPal account.</div>
                            </div>
                            <div className="bottom-area">
                                <p className="mt-14">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p>
                                {/* <button className="btn-shop orange-color" type="submit" onClick={()=>buyNow()}>Continue to payment</button> */}
                            </div>
                        </div>
                     <button className="btn-shop orange-color" type="submit" onClick={()=>buyNow(productId)}>Continue to payment</button>

                   { modalShow && <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />}
                </div>
            </div>
        </div>
    );
}

export default CheckoutMain;