import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/main.scss";
import { Provider } from 'react-redux';
import store from './store';
window.store = store;
const container = document.getElementById("root");
const rootContainer = ReactDOM.createRoot(container);
rootContainer.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
</Provider>
);

reportWebVitals();
