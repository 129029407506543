import React,{useEffect} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { getAllCategory } from "../../../actions/category.action";

const MenuItems = (props) => {
  const category = useSelector(state => state.category);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategory());
   }, []);
  const { parentMenu, secondParentMenu } = props;

  const location = useLocation();
  const arr=[];
  const renderCategories = (categories) => {
    let myCategories = [];
    
    for (let category of categories) {
     
      arr.push(category.name == "Career" ? true:"")
      // console.log("Umesh",arr,category);
      myCategories.push(
        <li key={category.name} 
        className={
          parentMenu === category.name
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
        >
          {
            category.parentId ? <NavLink
            to={arr.includes(true) ?`/career?cid=${category._id}` :`/courses/${category.slug}?cid=${category._id}`}>
              {category.name}
            </NavLink> :<NavLink  to={`/${category.name.toLowerCase()}`}
          className={location.pathname === `/${category.name}` ? "active-menu" : ""}> <span className="custom-nav">{category.name}</span></NavLink>
           
          }
          {category.children.length > 0 ? (<ul className="sub-menu"><li className={
          parentMenu === category.name
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }>{renderCategories(category.children)}</li></ul>) : null}
        </li>
      );
    }
    return myCategories;
  }

  return (
    <React.Fragment>

      <li
        className={
          parentMenu === "home"
            ? "rs-mega-menu menu-item-has-children current-menu-item"
            : "rs-mega-menu menu-item-has-children"
        }
      >
        <Link to='/'>
          <span className='custom-nav'> Home</span>
        </Link>
      </li>

      <li
        className={
          parentMenu === "about"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link
          to='/about'
          className={location.pathname === "/about" ? "active-menu" : ""}
        >
          <span className='custom-nav'> About Us</span>
        </Link>
      </li>
    
      {category.categories.length > 0 ? renderCategories(category.categories) : null}
      <li
        className={
          parentMenu === "franchise"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link
          to='/francise'
          className={location.pathname === "/francise" ? "active-menu" : ""}
        >
          <span className='custom-nav'>Franchise</span>
        </Link>
      </li>

      <li
        className={
          parentMenu === "service"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link
          to='/jiitsSchool'
          className={location.pathname === "/jiitsSchool" ? "active-menu" : " "}
        >
          <span className='custom-nav'>JIITS School</span>
        </Link>
      </li>
      <li
        className={
          parentMenu === "service"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link
          to='/service'
          as='#'
          className={location.pathname === "/service" ? "active-menu" : " "}
        >
          <span className='custom-nav'> Service</span>
        </Link>
      </li>

      <li
        className={
          parentMenu === "contact"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to='/contact'>
          <span className='custom-nav'> Contact</span>
        </Link>
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
