
import React,{useState } from 'react';
import axios from '../../helpers/axios';

const ContactForm = (props) => {
    const { submitBtnClass, btnText } = props;
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [pin, setPin] = useState("");
    const [message,setMessage] = useState("");
    const [msgopt,setMsgOpt] = useState("");

    const contactus = async (user)=>{
        const res = await axios.post(`/contact`,user);
        setMsgOpt(res.data.message)
        // console.log(res.data.message);
    }

    const submitProductForm = () => {
      const user = {
             name,
             email,
             contact,
             pin,
             message,
            };
         contactus(user);
            // dispatch(contactus(user));
        
       
        // console.log(name,email,contact,pin ,message);
    }
    return (
        <>
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="name" name="name" placeholder="Name" value={name} onChange={e=>setName(e.target.value)} required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="email" name="email" placeholder="E-Mail" value={email} onChange={e=>setEmail(e.target.value)} required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="phone" name="phone" placeholder="Phone Number" value={contact} onChange={e=>setContact(e.target.value)} required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="pin" name="pin" placeholder="Your PIN Code" value={pin} onChange={e=>setPin(e.target.value)} required />
                </div>

                <div className="col-12 mb-30">
                    <textarea className="from-control" id="message" name="message" placeholder="Your message Here" value={message} onChange={e=>setMessage(e.target.value)} required></textarea>
                </div>
            </div>
            <div className="btn-part">
                <button className={submitBtnClass ? submitBtnClass : 'readon learn-more submit'} type="button" onClick={submitProductForm}>{btnText ? btnText : 'Submit Now'}</button>
            </div>
        </>    
    );

}

export default ContactForm;