import React from "react";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import ContactForm from "../../components/Contact/ContactForm";
import ContactInfo from "../../components/Contact/ContactInfo";

import bannerbg from "../../assets/img/breadcrumbs/b3.jpg";

const ContactMain = () => {
  return (
    <React.Fragment>
      {/* SiteBreadcrumb */}
      <SiteBreadcrumb
        pageTitle='Contact'
        pageName='Contact'
        breadcrumbsImg={bannerbg}
      />
      {/* SiteBreadcrumb */}

      {/* Contact Section Start */}
      <div className='rs-contact style1 event-bg pt-110 md-pt-80 pb-100 md-pb-80'>
        <div className='container pb-66 md-pb-46'>
          <div className='row gutter-35'>
            <div className='col-md-4'>
              <ContactInfo
                boxClass='sm-mb-30'
                title='Address'
                iconClass='flaticon-location'
                address='Rohini Nagar Shyam Lawn,Nagpur MH-440013'
              />
            </div>
            <div className='col-md-4'>
              <ContactInfo
                boxClass='sm-mb-30'
                title='Email Address'
                iconClass='flaticon-email'
                email='mylondan186@gmail.com'
              />
            </div>
            <div className='col-md-4'>
              <ContactInfo
                boxClass=''
                title='Phone Number'
                iconClass='flaticon-phone'
                phone='(+91)9595-1160-99'
              />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row y-middle'>
            <div className='col-lg-6 md-mb-30'>
              <div className='map-canvas'>
                <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.1266605757646!2d79.06222251441!3d21.187126787730094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c10a7832dfe9%3A0x45a547153327adf5!2sShyam%20Lawn!5e0!3m2!1sen!2sin!4v1667375429256!5m2!1sen!2sin'></iframe>{" "}
              </div>
            </div>
            <div className='col-lg-6 pl-60 md-pl-14'>
              <div className='contact-widget'>
                <SectionTitle
                  sectionClass='sec-title3 mb-36'
                  titleClass='title black-color mb-14'
                  title='Get in Touch'
                  descClass='new-desc'
                  description='Have some suggestions or just want to say hi? Our  support team are ready to help you 24/7.'
                />
                {/* Contact Form */}
                <ContactForm submitBtnClass='btn-send' btnText='Submit' />
                {/* Contact Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Section End */}
    </React.Fragment>
  );
};

export default ContactMain;
