import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";

// import footerLogo1 from "../../../assets/img/logo/logo.png";
import footerLogo1 from "../../../assets/img/logo/update-logo1.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../../actions/category.action";

const Footer = (props) => {
  const category = useSelector((state) => state.category);
  const dispatch =useDispatch();

  useEffect(() => {
    dispatch(getAllCategory());
  }, []);
  // console.log("Footer",category.categories[0].children);
  const { footerLogo, footerClass, footerTopClass } = props;
  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className={`footer-top ${footerTopClass}`}>
        <div className='container'>
          <div className='row  '>
            <div className='footer-logo'>
              <Link to='/' as='/'>
                <img
                  className='logo-img'
                  src={footerLogo ? footerLogo : footerLogo1}
                  alt='Logo'
                />
              </Link>
            </div>
            <div className='col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50'>
              <div className='col-lg-10 col-md-20 col-sm-20 footer-widget md-mb-50'>
                <h3 className='widget-title'>Address</h3>
                <ul className='address-widget'>
                  <li>
                    <i className='flaticon-location'></i>
                    <div className='desc'>
                      Rohini Nagar Shyam Lawn Nagpur MH-40013{" "}
                    </div>
                  </li>
                  <li>
                    <i className='flaticon-call'></i>
                    <div className='desc'>
                      <a href='tel:9595116099'>(+91)9595-116-099</a>
                    </div>
                  </li>
                  <li>
                    <i className='flaticon-email '></i>
                    <div className='desc'>
                      <a href='mailto:mylondan186@gmail.com'>
                        mylondan186@gmail.com
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <ul className='footer_social  mt-20'>
                <li>
                  <a href='#'>
                    <i className='fa fa-facebook-f h-10 w-10 '></i>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='fa fa-twitter h-20 w-20'></i>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='fa fa-pinterest'></i>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='fa fa-google-plus'></i>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='fa fa-instagram'></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className='col-lg-3 col-md-12 col-sm-12 pl-50 md-pl-10 footer-widget md-mb-50'>
              <h3 className='widget-title'>Categories</h3>
              <ul className='site-map'>
              {category.categories[0]?.children.map((curElem, index) => {
               
                return (             
                  index <=5 ? <li>
                  <Link to={`course/${curElem.slug}?cid=${curElem._id}`}>{curElem.name}</Link>
                </li>:""
                )})}
                {/* // <li>
                //   <Link to='/course-2'>web Development</Link>
                // </li>
                // <li>
                //   <Link to='/course/course-single'>Web Designing</Link>
                // </li>
                // <li>
                //   <Link to='/course/course-single'>Business Classes</Link>
                // </li>
                // <li>
                //   <Link to='/course/course-single'>Animation Classes</Link>
                // </li>
                // <li>
                //   <Link to='/shop/my-account'>Networking Classes</Link>
                // </li> */}
              </ul>
            </div>
            <div className='col-lg-3 col-md-12 col-sm-12 pl-50 md-pl-10 footer-widget md-mb-50'>
              <h3 className='widget-title'>Courses</h3>
              <ul className='site-map'>
                <li>
                  <Link to='#'>JAVA</Link>
                </li>
                <li>
                  <Link to='#'>PHP</Link>
                </li>
                <li>
                  <Link to='#'>DOTNET</Link>
                </li>
                <li>
                  <Link to='#'>React JS</Link>
                </li>
                <li>
                  <Link to='#'>ANDROID</Link>
                </li>
                <li>
                  <Link to='#'>Data Science</Link>
                </li>
              </ul>
            </div>

            <div className='col-lg-3 col-md-10 col-sm-12 pl-5 md-pl-5 footer-widget md-mb-50'>
              <h3 className='widget-title'>Support</h3>
              <ul className='site-map'>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to='/sitemap'>Sitemap</Link>
                </li>
                <li>
                  <Link to='/privacyPolicy'>Privacy Policy</Link>
                </li>
                <li>
                  <Link to='/cookiePolicy'>Cookie Policy</Link>
                </li>
                <li>
                  <Link to='/webUserPolicy'>Web User Policy</Link>
                </li>
                <li>
                  <Link to='/terms&Condition'>Term and Services</Link>
                </li>
              </ul>
            </div>

            {/* <div className='col-lg-3 col-md-12 col-sm-12 footer-widget'>
              <h3 className='widget-title'>Support</h3>
              <div className='recent-post mb-20'>
                <div className='post-img'>
                  <img src={postImg1} alt='blog image' />
                </div>
                <div className='post-item'>
                  <div className='post-desc'>
                    <Link to='/blog/single-post-right-sidebar'>
                      High school program starting soon 2021
                    </Link>
                  </div>
                  <span className='post-date'>
                    <i className='fa fa-calendar-check-o'></i>
                    October 15, 2020
                  </span>
                </div>
              </div>
              <div className='recent-post mb-20'>
                <div className='post-img'>
                  <img src={postImg2} alt='blog image' />
                </div>
                <div className='post-item'>
                  <div className='post-desc'>
                    <Link to='/blog/single-post-right-sidebar'>
                      Shutdown of schools extended to Aug 31{" "}
                    </Link>
                  </div>
                  <span className='post-date'>
                    <i className='fa fa-calendar-check-o'></i>
                    April 25, 2020
                  </span>
                </div> */}
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
