import React from "react";
import career1 from "../../assets/img/carrer/completedpro.png";
import career2 from "../../assets/img/carrer/expertteam.png";
import career3 from "../../assets/img/carrer/hrscoding.png";
import career4 from "../../assets/img/carrer/business.png";

const CoursePart = (props) => {
  return (
    <div
      id='rs-popular-course'
      className='rs-popular-courses style1 orange-style pt-100 pb-100 md-pt-70 md-pb-50'
    >
      <div className='container'>
        <div className='row '>
          <div className=''>
            <span className='career-heading'>
              Start your career with a growing company in a learning environment
              and work with a team of skilled and experienced professionals.
            </span>
          </div>

          <div className=' career-content d-flex justify-content-between align-items-center p-3 mb-20'>
            <div>
              <img src={career1} />
              <h1>3000+</h1>
              <span>Completed Projects</span>
            </div>
            <div>
              {" "}
              <img src={career2} />
              <h1>100+</h1>
              <span>Experts Team</span>
            </div>
            <div>
              {" "}
              <img src={career3} />
              <h1>25000+</h1>
              <span>Hours of Coding</span>
            </div>
            <div>
              {" "}
              <img src={career4} />
              <h1>11</h1>
              <span>Years of Business</span>
            </div>
          </div>

          <div>
            <div className=''>
              <h1>Achieve New Goals In Your Career</h1>
              <p>
                Work within a healthy working environment with a very supportive
                team of excellent programmers, developers, architects,
                salespersons, testers, designers, and managers at JIITS
                Technology.
              </p>
            </div>
            <div>
              <p>Supportive Team</p>
              <p>Training & Internship</p>

              <p>career-height-Innovation & Creativity</p>

              <p>Career Growth</p>
              <p>Decipline & Transparency</p>
              <p>Recognition & Feedback</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePart;
