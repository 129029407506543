import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import "./style.css";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import BootModal from 'react-bootstrap/Modal';
import axios from '../../../helpers/axios';

import { IoIosArrowDown, IoIosCart, IoIosSearch } from "react-icons/io";
import {
  Modal,
  MaterialInput,
  MaterialButton,
  DropdownMenu,
} from "../MaterialUI";
import { useDispatch, useSelector } from "react-redux";
import { login, signout, getCartItems, signup as _signup } from "../../../actions";

const TopHeader = (props) => {
  const { topBarClass, emailAddress, phoneNumber, Location } = props;
  
  const [loginModal, setLoginModal] = useState(false);
  const [signup, setSignup] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [coin, setCoin] = useState("");

  
	const [payment, setPayment] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [signature, setSignature] = useState("");
const [modalShow, setModalShow] = useState(false);
const [thankModalShow, setThankModalShow] = useState(false);

  //coin
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // end coin

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // // state cart value
  // const cart = useSelector((state) => state.cart);


/**
 * @author
 * @function Header
 **/
const buyCoin = async (e)=>{
  e.preventDefault();
  const res = await axios.get(`/payCoin/${coin}`);
	console.log("Payment Order",res);
	if (res.status !== 200) {
	}
	// alert(productId);
	var options = {
	  key: "rzp_test_fRUmy0uh5zcrHR", // Enter the Key ID generated from the Dashboard
	  amount: res.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
	  currency: res.data.currency,
	  name: "JIITS",
	  description: res.data.notes.desc,
	  image: "https://example.com/your_logo",
	  order_id: res.data.id,

	  handler: async function (response) {
		// alert(response.razorpay_payment_id);
		// alert(response.razorpay_order_id);
		// alert(response.razorpay_signature);
		const {data} = await axios.post(`/verify/razorpay-signature`,response);
		console.log("verify",data)
		setOrderId(response.razorpay_order_id);
		setPaymentId(response.razorpay_payment_id);
		setSignature(response.razorpay_signature);
		setPayment(true);
		if(data.success){
      const res = await axios.put(`/buyCoin/`);
	    console.log("Payment Order",res);
			// dispatch(addVirtualCard(form)).then(()=>{
			// 	setThankModalShow(true);
				
			// })
		//    dispatch(addOrder(payload)).then(()=>{
		// 		// if(user.orderSuccess){
		// 			
		// 		//   }else{
		// 		//     alert("Sorry!! Your Order Has Been Failed")
		// 		//   }
			  
		//    })
		  
		}else{
			alert(data.message)
		}
	  },
	  prefill: {
		name: "Priyanshuvi",
		email: "priyanshuvi123@example.com",
		contact: "7972757959",
	  },
	  // notes: {
	  //   address: "Razorpay Corporate Office",
	  // },
	  // theme: {
	  //   color: "#3399cc",
	  // },
	};
	var rzp1 = new window.Razorpay(options);
	rzp1.open();
	rzp1.on("payment.failed",function (response){
	  alert(response.error.code);
	  alert(response.error.description);
	  alert(response.error.source);
	  alert(response.error.step);
	  alert(response.error.reason);
	  alert(response.error.metadata.order_id);
	  alert(response.error.metadata.payment_id);
	})

  
    
}

//Coin
function Coin() {
  return (
    <>
    <button className='apply-btn' style={{background: "#ff5421",
                    color: "white",
                    borderRadius: "5px",
                    height: "40px"}} onClick={handleShow}>
                  Buy Coin
                </button>
      {/* <Button variant="primary" >
        Launch demo modal
      </Button> */}

      <BootModal show={show} onHide={handleClose}>
        <BootModal.Header closeButton>
          <BootModal.Title>My coin Rs-200</BootModal.Title>
        </BootModal.Header>
        <BootModal.Body>
              {/* coin */}
            <div class="coin copper"></div>
                {/* <div class="coin flip silver"></div>
                <div class="coin"></div> */}
          <Form className="form" onSubmit={buyCoin}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Enter Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Amount"
                autoFocus
                value={coin}
                onChange={e=>setCoin(e.target.value)}
              /> 
              <div className='d-flex'>
                 <div className="small-card"><p>Less Than 1000 For Silver Coin</p></div>
                 <div className="small-card"><p>More Than 2000 For Copper Coin</p></div>
                 <div className="small-card"><p>More Than 3000 For Gold Coin</p></div>
              </div>
            </Form.Group>
           
            <Button variant="primary" type="submit">
              Buy Now
          </Button>
          </Form>
           
        </BootModal.Body>
      </BootModal>
    </>
  );
}
//end Coin

 const userSignup = () => {
  const user = { firstName, lastName, email, password };
  if (
    firstName === "" ||
    lastName === "" ||
    email === "" ||
    password === ""
  ) {
    return;
  }

  dispatch(_signup(user));
};

const userLogin = () => {
  if (signup) {
    userSignup();
  } else {
    dispatch(login({ email, password }));
  }
};

const logout = () => {
  dispatch(signout());
};

useEffect(() => {
  if (auth.authenticate) {
    setLoginModal(false);
  }
}, [auth.authenticate]);

 return (
    <>
      <div
      className={
        topBarClass ? topBarClass : "topbar-area home8-topbar hidden-md"
      }
    >
      <div className='container'>
        <div className='row y-middle'>
          <div className='col-md-7'>
            <ul className='topbar-contact'>
              {emailAddress ? (
                <li>
                  <i className='flaticon-email'></i>
                  <a href={"mailto:" + emailAddress}>{emailAddress}</a>
                </li>
              ) : (
                ""
              )}
              {phoneNumber ? (
                <li>
                  <i className='flaticon-call'></i>
                  <a href={"tel:+" + phoneNumber}>{phoneNumber}</a>
                </li>
              ) : (
                ""
              )}
              {Location ? (
                <li>
                  <i className='flaticon-location'></i>
                  {Location}
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>

          <div className='col-md-5 text-end'>
            <ul className='topbar-right'>
              <li className='login-register'>
                <div style={{display:"flex",position:"absolute",right:"270px",cursor:"pointer"}}>
                   {auth.user.fullName ?<><i className='fa fa-sign-out' title="Sign-out" onClick={logout}></i>{auth.user.fullName}</>:<><i className='fa fa-sign-in'></i>
                    <div onClick={() => {
                    setSignup(false);
                    setLoginModal(true);
                    }}>Login </div> /
                    <div onClick={() => {
                    setLoginModal(true);
                    setSignup(true);
                  }}> Register</div></>}
                    {/* Welcome Umesh Bind &nbsp;&nbsp;
                   <i className='fa fa-sign-out' title="Sign-out"></i> */}
                   

                </div>
              
              </li>
              
              <li className='btn-part'>
                {
                  <Coin />
                }
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div className="header">
      <Modal visible={loginModal} onClose={() => setLoginModal(false)}>
        <div className="authContainer">
          <div className="row1">
            <div className="leftspace">
              <h2>{signup ? "Register":"Login"}</h2>
              <p>Get access to your Orders, Wishlist and Recommendations</p>
            </div>
            <div className="rightspace">
              <div className="loginInputContainer">
                {auth.error && (
                  <div style={{ color: "red", fontSize: 12 }}>{auth.error}</div>
                )}
                {signup && (
                  <MaterialInput
                    type="text"
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                )}
                {signup && (
                  <MaterialInput
                    type="text"
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                )}

                <MaterialInput
                  type="text"
                  label="Email/Mobile Number"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <MaterialInput
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  // rightElement={<a href="#">Forgot?</a>}
                />
                <MaterialButton
                  title={signup ? "Register" : "Login"}
                  bgColor="#fb641b"
                  textColor="#ffffff"
                  style={{
                    margin: "40px 0 20px 0",
                  }}
                  onClick={userLogin}
                />
                <p style={{ textAlign: "center" }}>OR</p>
                <MaterialButton
                  title="Request OTP"
                  bgColor="#ffffff"
                  textColor="#2874f0"
                  style={{
                    margin: "20px 0",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
        {/* logo ends here */}

        {/* search component */}
        {/* <div
          style={{
            padding: "0 10px",
          }}
        >
          <div className="searchInputContainer">
            <input
              className="searchInput"
              placeholder={"search for products, brands and more"}
            />
            <div className="searchIconContainer">
              <IoIosSearch
                style={{
                  color: "#2874f0",
                }}
              />
            </div>
          </div>
        </div> */}
        {/* search component ends here */}

        
    </div>
    </>
  );
};

export default TopHeader;

