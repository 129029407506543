import React from "react";

import SectionTitle from "../../components/Common/SectionTitle";

// About Image
import AboutImage from "../../assets/img/about/ab3.png";

const AboutText = (props) => {
  return (
    <div className='rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6 order-last padding-0 md-pl-14 md-pr-14 md-mb-30'>
            <div className='img-part'>
              <img className='about-main' src={AboutImage} alt='About Image' />
            </div>
          </div>
          <div className='col-lg-6 pr-70 md-pr-14'>
            <div className='about-content'>
              {/* Section Title Start */}
              <SectionTitle
                sectionClass='sec-title mb-44 md-mb-0'
                subtitleClass='sub-title orange'
                subtitle='About JIITS'
                titleClass='title mb-16'
                title='Welcome to The JIITS Online Learning'
                descClass='bold-text mb-22'
                description='JIITS will provide you with excellent training, skills and confidence required to begin a successful career. We expose the trainees to the latest technologies and give them an opportunity to apply their learning and knowledge in the workplace. This enables them to contribute towards developing solutions that create value. With our vast array of career opportunities and training, we help them to discover their potential and become “highly sought” technology experts.'
                secondDescClass='desc'
                secondDescription='The evolving IT industry needs technology experts. There is a massive disconnect between what is taught in colleges and what the industry expects. After graduation, most of the freshers have only theoretical knowledge, so there is a gap between the fresher’s knowledge and industry’s expectation. Having identified this need, we have set up a ‘Knowledge arm of Clover Infotech’ to prepare the next-generation IT workforce. And that’s where Clover Academy comes in – to help freshers gain practical exposure and the required training.'
              />
              {/* Section Title End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutText;
