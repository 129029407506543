import React, { Component } from "react";
import Slider from "react-slick";
import FeatureStyle1 from "../Feature/FeatureStyle1";
import { Link } from "react-router-dom";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default class SliderDefault extends Component {
  render() {
    const sliderSettings = {
      fade: true,
      infinite: true,
      autoplay: true,
      centerMode: false,
      speed: 500,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 1,

      nextArrow: <ArrowForwardIosIcon />,
      prevArrow: <ArrowBackIosIcon />,

      responsive: [
        {
          breakpoint: 200,
          settings: {
            arrows: true,
          },
        },
      ],
    };

    const settings1 = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 200,
          settings: {
            arrows: true,
            infinite: true,
          },
        },
      ],
    };

    return (
      <>
        <div className='rs-slider main-home'>
          <Slider {...sliderSettings}>
            <div className='slider-content slide1'>
              <div className='container'>
                <div className='content-part'>
                  <div className='sl-sub-title'>Start to learning today</div>

                  <h1 className='sl-title'>
                    Find The Best Trainers And Build Your Future...
                  </h1>

                  <div className='sl-sub-title2'>
                    JOB IN IT TRAINING & SOLUTIONS
                  </div>
                  <div className='sl-btn'>
                    {/* <Link className='readon orange-btn main-home ' to='/course'>
                      Find Course
                    </Link> */}

                    <Link
                      className='menu-item1  orange-btn main-home '
                      to='/course'
                    >
                      <p>Find Course</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className='slider-content slide2'>
              <div className='container'>
                <div className='content-part'>
                  <div className='sl-sub-title'>Start to learning today</div>
                  <h1 className='sl-title'>
                    Explore Interests and Career With Courses
                  </h1>
                  <div className='sl-btn'>
                    {/* <Link className='readon orange-btn main-home' to='/course'>
                      Find Course
                    </Link> */}

                    <Link
                      className='menu-item1  orange-btn main-home '
                      to='/course'
                    >
                      <p>Find Course</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* 3 */}
            <div className='slider-content slide1'>
              <div className='container'>
                <div className='content-part'>
                  <div className='sl-sub-title'>Start to learning today</div>

                  <h1 className='sl-title'>
                    Find The Best Trainers And Build Your Future...
                  </h1>

                  <div className='sl-sub-title2'>
                    JOB IN IT TRAINING & SOLUTIONS
                  </div>
                  <div className='sl-btn'>
                    {/* <Link className='readon orange-btn main-home ' to='/course'>
                      Find Course
                    </Link> */}

                    <Link
                      className='menu-item1 orange-btn main-home '
                      to='/course'
                    >
                      <p>Find Course</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* 4 */}
            <div className='slider-content slide2'>
              <div className='container'>
                <div className='content-part'>
                  <div className='sl-sub-title'>Start to learning today</div>
                  <h1 className='sl-title'>
                    Explore Interests and Career With Courses
                  </h1>
                  <div className='sl-btn'>
                    <Link
                      className='menu-item1  orange-btn main-home '
                      to='/course'
                    >
                      <p>Find Course</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
          <FeatureStyle1 />
        </div>

        <div className='ads'>
          <h2>Advertisement</h2>
          <Slider {...settings1}>
            <div className='ads-style'>
              <h3>Ads1</h3>
            </div>
            <div className='ads-style'>
              <h3>Ads2</h3>
            </div>
            <div className='ads-style'>
              <h3>Ads3</h3>
            </div>
            <div className='ads-style'>
              <h3>Ads4</h3>
            </div>
            <div className='ads-style'>
              <h3>Ads5</h3>
            </div>
            <div className='ads-style'>
              <h3>Ads6</h3>
            </div>
          </Slider>
        </div>
      </>
    );
  }
}
