import React from "react";
import Tilty from "react-tilty";
import SiteBreadcrumb from "../../../components/Common/Breadcumb";
import SectionTitle from "../../../components/Common/SectionTitle";
import ServiceForm from "../../../components/ServiceNew/ServiceForm";
import ServiceInfo from "../../../components/ServiceNew/ServiceInfo";
import banner from "../../../assets/img/breadcrumbs/inner12.jpg";

//services
import Ser from "../../../components/Service/index";

const ServiceMain = () => {
  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageTitle='Our Services'
        pageName='Service'
        breadcrumbsImg={banner}
      />

      {/* SiteBreadcrumb */}

      {/* Contact Section Start */}
      <div className='rs-contact style2 pt-110 md-pt-80 pb-110 md-pb-80'>
        <Ser />

        <div className='contact-widget event-bg'>
          <SectionTitle
            sectionClass='sec-title text-center mb-50'
            titleClass='title black-color mb-14'
            title='Quick Contact'
            descClass='desc big'
            description={
              <>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, eius
                to mod <br /> tempor incidi dunt ut dolore.
              </>
            }
          />

          {/* Contact Form */}
          <ServiceForm submitBtnClass='btn-send' btnText='Submit' />
          {/* Contact Form */}
        </div>
      </div>

      {/* Contact Section End */}
    </React.Fragment>
  );
};

export default ServiceMain;
