import React from "react";
import { Link } from "react-router-dom";

import CourseSidebar from "./CourseSidebarSection";
import CourseSingleTwo from "../../components/Courses/CourseSingleTwo";

// Course courseImg

// import ms1 from "../../assets/img/courses_img/images/microsoftOff1.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsBySlug } from "../../actions/product.action";

const CoursePart = ({cidAndSlug}) => {
  const product = useSelector(state=>state.product);
  const dispatch = useDispatch();
  const {cId,cid,courseSlug} = cidAndSlug;
 useEffect(() => {
    dispatch(getProductsBySlug(cid));
  }, [courseSlug]);
  console.log("Umesh",product.products);

  const listClassAdd = () => {
    document.getElementById("rs-popular-course").classList.add("list-view");
  };

  const listClassRemove = () => {
    document.getElementById("rs-popular-course").classList.remove("list-view");
  };

  return (
    <div
      id='rs-popular-course'
      className='rs-popular-courses style1 course-view-style orange-style rs-inner-blog white-bg pt-100 pb-100 md-pt-70 md-pb-80'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 col-md-12 order-last'>
            <CourseSidebar {...cidAndSlug}/>
          </div>
          <div className='col-lg-8 pr-50 md-pr-14'>
            <div className='course-search-part'>
              <div className='course-view-part'>
                <div className='view-icons'>
                  <button onClick={listClassRemove} className='view-grid mr-10'>
                    <i className='fa fa-th-large'></i>
                  </button>
                  <button onClick={listClassAdd} className='view-list'>
                    <i className='fa fa-list-ul'></i>
                  </button>
                </div>
                <div className='view-text'>Showing 1-9 of 11 results</div>
              </div>
              <div className='type-form'>
                <form method='post' action='#'>
                  <div className='form-group mb-0'>
                    <div className='custom-select-box'>
                      <select id='timing'>
                        <option>Default</option>
                        <option>Newest</option>
                        <option>Old</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='course-part clearfix'>
              {
                product.products.map((curElem,index)=>
                <CourseSingleTwo
                courseClass='courses-item'
                courseImg={curElem.banner[0].img}
                courseTitle={curElem.name}
                coursePrice={`₹ ${curElem.price}`}
                courseCategory={courseSlug}
                courseId={curElem._id}
              />
                )
              }
           </div>
            <div className='pagination-area orange-color text-center mt-30 md-mt-0'>
              <ul className='pagination-part'>
                <li className='active'>
                  <Link to='#'>1</Link>
                </li>
                <li>
                  <Link to='#'>2</Link>
                </li>
                <li>
                  <Link to='#'>
                    Next <i className='fa fa-long-arrow-right'></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePart;
