import React from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import CourseMain from "./CourseMain";

// Image
import Logo from "../../assets/img/logo/update-logo1.png";

import bannerbg from "../../assets/img/breadcrumbs/breadcrumbs.jpg";
import { useParams, useSearchParams } from "react-router-dom";

const CourseOne = () => {
  const [searchParams,setSearchParams] = useSearchParams();
  const cId = searchParams.get('cid');
  const slugData = useParams();
  const {slug,cid} = slugData;
  const courseSlug = cid.split("-").splice(0,2).join(" ")
  const cidAndSlug={
    cId,cid,courseSlug
  };
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu='course'
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass='right_menu_togle hidden-md'
        headerClass='full-width-header header-style1 home8-style4'
        TopBar='enable'
        TopBarClass='topbar-area home8-topbar'
        emailAddress='mylondan186@gmail.com'
        Location='Rohini Nagar Shyam Lawn,Nagpur MH-440013 '
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle={courseSlug}
        innerClass='breadcrumbs-text'
        pageName={courseSlug}
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-start */}

      {/* Course Main */}
      <CourseMain cidAndSlug={cidAndSlug} />
      {/* Course Main */}

      <Newsletter
        sectionClass='rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70'
        titleClass='title mb-0 white-color'
      />

      <Footer footerClass='rs-footer home9-style main-home' />
      <SearchModal />
    </React.Fragment>
  );
};

export default CourseOne;
