import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";
import CategoriesSingle from "../../components/Categories/CategoriesSingle";

// Categories Image
import image1 from "../../assets/img/categories/main-home/1.jpg";
// Categories Icon
import icon1 from "../../assets/img/categories/main-home/icon/1.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../actions/category.action";

const Categories = () => {
  const category = useSelector((state) => state.category);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCategory());
  }, []);

  // console.log("Course Subcategory", category.categories[0]?.children[0]?.name);
  return (
    <div className='rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-80'>
      <div className='container'>
        <SectionTitle
          sectionClass='sec-title3 text-center mb-44'
          subtitleClass='sub-title'
          subtitle='Top Categories'
          titleClass='title black-color'
          title='Popular Online Categories'
        />
        <div className='row mb-34'>
          {category.categories[0]?.children.map((curElem, index) => {
            return (
              <>
                <div className='col-lg-4 col-md-6 mb-30'>
                  <CategoriesSingle
                    catLink={`course/${curElem.slug}?cid=${curElem._id}`}
                    itemClass='categories-items'
                    image={image1}
                    title={curElem.name}
                    quantity='02 Courses'
                    iconImg={icon1}
                  />
                </div>
              </>
            ) 
          })}
          {/* <div className='text-center'>
            <Link
              className='readon orange-btn main-home'
              to='/course-categories'
            >
              View All categories{" "}
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Categories;
