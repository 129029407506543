import { Modal,Button } from "react-bootstrap";
 function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Login / Register
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Please Login / Register</h6>
          {/* <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} style={{height: "31px",
           padding: "0 0 0",
            width: "71px"}}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
    }
  //End Modal
  export default MyVerticallyCenteredModal