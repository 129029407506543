import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import SectionTitle from "../../components/Common/SectionTitle";

// About Image
// import countIcon1 from "../../assets/img/about/style3/icons/1.png";
import countIcon1 from "../../assets/img/about/style3/icons/1.png";

import countIcon2 from "../../assets/img/about/style3/icons/2.png";
import countIcon3 from "../../assets/img/about/style3/icons/3.png";
import img1 from "../../assets/img/about/employees-2.png";
import img2 from "../../assets/img/about/user-2.png";
import img3 from "../../assets/img/about/gurantee-2.png";
import img4 from "../../assets/img/about/quality-2.png";

const AboutCounter = () => {
  const [state, setState] = useState(true);

  const counters = [
    {
      countNum: 2,
      countTitle: "Students",
      counterPrefix: "k+",
      countIcon: countIcon1,
    },
    {
      countNum: 50,
      countTitle: "Above CGPA",
      counterPrefix: "",
      countIcon: countIcon2,
    },
    {
      countNum: 100,
      countTitle: "Placements",
      counterPrefix: "%",
      countIcon: countIcon3,
    },
  ];

  return (
    <div id='rs-about' className='rs-about style3 pt-110 md-pt-70'>
      <div className='container'>
        <div className='row y-middle'>
          <div className='about-intro1 md-pr-20'>
            <SectionTitle
              sectionClass='sec-title'
              subtitleClass='sub-title orange'
              subtitle='About JIITS ?'
              titleClass='title mb-20'
              title='Training Jobs Placements Outsourcing'
              descClass='desc big'
              description='JIITS Solution central India leading and best information technology computer education and self-income earning center In Nagpur. The team is very experience, helpful and cooperative teachers.
JIIT Solution Opening job Nagpur plus Vidharbh or all India.JIIT Solutions Provide best knowledge of IT Computer education or palest the job. Our think is making a bigger Platform or IT Hub in Nagpur JIITS Solution in Nagpur offer World class training in courses of c,c++, java, advance, java, oracle, visual Basic, Android, Net VB, php, VB net, Asp.net,c# ,Net, AutoCAD , Revit, Maya Animation, Flash Photoshop,Excel,Pro-e, Catia unigraphics, 3d Max, CorelDraw, graphic, Ansys software testing, Hardwar & Networking, Tally, course, Web or Portal Designing, IT Core Services & all Tally accounting pr software course available. '
            />{" "}
            <br />
            <br />
          </div>
          <div className='col-lg-4 lg-pr-0 md-mb-30'>
            <div className='about-intro  md-pr-16'>
              <SectionTitle
                className=''
                sectionClass='sec-title'
                subtitleClass='sub-title orange'
                subtitle='OUR CORE VALUE SYSTEM ?'
                titleClass='title mb-20'
                descClass='desc big'
                description={`We create all types of IT knowledgeable & Income
                 we earning able person JIITS Solution trainers are 
                 passion able about the teaching in our students and 
                 they support in front of their scholarly or creative
                  work. JIIT Solution Expert Education Brilliance and
                   encourage self-income & IT Education experience We
                    believe helpful process are the important key to 
                    successful learning & earning. We spend much time on 
                    a developing process & explain to particular example
                     to help earning income JIIT Solution aims to facilitated 
                     the better quality education and intellectually satisfying
                      Learning experience to every learns. Demand for an enjoyable,
                       incomable, vale adding and personalized learning experience 
                       has Become the driving force for technology development. 
                       JIITS Solution Maharashtra India a better learning & job
                        provider auto system. JIIT Solution offers IT literacy
                         and functionally education and training to learners in abroad.`}
              />
            </div>
          </div>

          <div className='col-lg-8 pl-82 md-pl-14'>
            {counters && (
              <div className='row rs-counter couter-area'>
                {counters
                  .map((counter, num) => (
                    <div key={num} className='col-md-4 sm-mb-30'>
                      <div className='counter-item one'>
                        <img
                          className='count-img'
                          src={counter.countIcon}
                          alt=''
                        />
                        <h2 className='number rs-count'>
                          <CountUp
                            start={state ? 0 : counter.countNum}
                            end={counter.countNum}
                            duration={10}
                            onEnd={() => setState(false)}
                          />
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                          <span className='counter-prefix'>
                            {counter.counterPrefix}
                          </span>
                        </h2>
                        <h4 className='title mb-0'>{counter.countTitle}</h4>
                      </div>
                    </div>
                  ))
                  .slice(0, 1)}
                {counters
                  .map((counter, num) => (
                    <div key={num} className='col-md-4 sm-mb-30'>
                      <div className='counter-item two'>
                        <img
                          className='count-img'
                          src={counter.countIcon}
                          alt=''
                        />
                        <h2 className='number rs-count'>
                          3.
                          <CountUp
                            start={state ? 0 : counter.countNum}
                            end={counter.countNum}
                            duration={10}
                            onEnd={() => setState(false)}
                          />
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                          <span className='counter-prefix'>
                            {counter.counterPrefix}
                          </span>
                        </h2>
                        <h4 className='title mb-0'>{counter.countTitle}</h4>
                      </div>
                    </div>
                  ))
                  .slice(1, 2)}
                {counters
                  .map((counter, num) => (
                    <div key={num} className='col-md-4'>
                      <div className='counter-item three'>
                        <img
                          className='count-img'
                          src={counter.countIcon}
                          alt=''
                        />
                        <h2 className='number rs-count'>
                          <CountUp
                            start={state ? 0 : counter.countNum}
                            end={counter.countNum}
                            duration={10}
                            onEnd={() => setState(false)}
                          />
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                          <span className='counter-prefix'>
                            {counter.counterPrefix}
                          </span>
                        </h2>
                        <h4 className='title mb-0'>{counter.countTitle}</h4>
                      </div>
                    </div>
                  ))
                  .slice(2, 3)}
              </div>
            )}
          </div>
        </div>
      </div>
      <section className='sptb bg-white' id='sec1'>
        <div className='container'>
          <div className='section-title d-md-flex mb-5'>
            <div>
              <h2>Why Only JIITS?</h2>
              <p className='fs-18 lead'>
                • We create all types of IT knowledgeable &amp; Income{" "}
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='mb-lg-0 mb-4 box-shadow about-2 p-5'>
                <div className=' text-center'>
                  <div className='icon-bg icon-service about'>
                    <img src={img1} alt='img' />
                  </div>
                  <div className='servic-data mt-3'>
                    <h4 className='font-weight-semibold mb-2'>Experience</h4>
                    <p className='mb-0'>
                      We have teams of experience IT trainer
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='mb-lg-0 mb-4 box-shadow p-5 br-bl-10 about-2 br-tr-10'>
                <div className='text-center'>
                  <div className=' icon-bg icon-service about bg-white br-100'>
                    <img src={img2} alt='img' />
                  </div>
                  <div className='servic-data mt-3'>
                    <h4 className='font-weight-semibold mb-2'>
                      Professionality
                    </h4>
                    <p className='mb-0'>
                      Nam libero tempore, cum soluta nobis est eligendi cumque
                      facere possimus
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='mb-sm-0 mb-4 box-shadow about-2 p-5'>
                <div className='text-center'>
                  <div className='icon-bg icon-service about'>
                    <img src={img3} alt='img' />
                  </div>
                  <div className='servic-data mt-3'>
                    <h4 className='font-weight-semibold mb-2'>Guarantee</h4>
                    <p className='mb-0'>
                      Nam libero tempore, cum soluta nobis est eligendi cumque
                      facere possimus
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='mb-sm-0 mb-4 box-shadow about-2 p-5'>
                <div className='text-center'>
                  <div className='icon-bg icon-service about'>
                    <img src={img4} alt='img' />
                  </div>
                  <div className='servic-data mt-3'>
                    <h4 className='font-weight-semibold mb-2'>Quality</h4>
                    <p className=' mb-0'>
                      Nam libero tempore, cum soluta nobis est eligendi cumque
                      facere possimus
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutCounter;
