import { userConstants,virtualConstants } from "../actions/constants";

const initState = {
  address: [],
  orders: [],
  orderDetails: {},
  error: null,
  loading: false,
  orderFetching: false,
  placedOrderId: null,
  orderSuccess:false,
  virtualCard:false,
  virtualDetails:{},
};

export default (state = initState, action) => {
  switch (action.type) {
    case userConstants.GET_USER_ADDRESS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case userConstants.GET_USER_ADDRESS_SUCCESS:
      state = {
        ...state,
        address: action.payload.address,
        loading: false,
      };
      break;
    case userConstants.GET_USER_ADDRESS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case userConstants.ADD_USER_ADDRESS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case userConstants.ADD_USER_ADDRESS_SUCCESS:
      state = {
        ...state,
        address: action.payload.address,
        loading: false,
      };
      break;
    case userConstants.ADD_USER_ADDRESS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case userConstants.GET_USER_ORDER_REQUEST:
      state = {
        ...state,
        orderFetching: true,
      };
      break;
    case userConstants.GET_USER_ORDER_SUCCESS:
      state = {
        ...state,
        orders: action.payload.orders,
        orderFetching: false,
      };
      break;
    case userConstants.GET_USER_ORDER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        orderFetching: false,
      };
      break;
    case userConstants.GET_USER_ORDER_DETAILS_REQUEST:
      break;
    case userConstants.GET_USER_ORDER_DETAILS_SUCCESS:
      state = {
        ...state,
        orderDetails: action.payload.order,
      };
      break;
    case userConstants.GET_USER_ORDER_DETAILS_FAILURE:
      break;
    case userConstants.ADD_USER_ORDER_SUCCESS:
      state = {
        ...state,
        placedOrderId: action.payload.order._id,
        orderSuccess:true,
      };
      break;
//add virtual card
      case virtualConstants.ADD_VIRTUAL_REQUEST:
      state = {
        ...state,
        virtualCard: false,
      };
      break;
    case virtualConstants.ADD_VIRTUAL_SUCCESS:
      state = {
        ...state,
        virtualDetails: action.payload.virtual,
        virtualCard: true,
      };
      break;
    case virtualConstants.ADD_VIRTUAL_FAILURE:
      state = {
        ...state,
        virtualCard: false,
        error: action.payload.error,
      };
      break;
      // get virtual card
      case virtualConstants.GET_VIRTUAL_REQUEST:
        state = {
          ...state,
          virtualCard: false,
        };
        break;
      case virtualConstants.GET_VIRTUAL_SUCCESS:
        state = {
          ...state,
          virtualDetails: action.payload.virtual,
          virtualCard: true,
        };
        break;
      case virtualConstants.GET_VIRTUAL_FAILURE:
        state = {
          ...state,
          virtualCard: false,
          error: action.payload.error,
        };
        break;


  }

  return state;
};
